import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import WhatsappButton from './social-bar/WhatsAppButton';

export default function FooterBar() {
  return (

    <AppBar
      position="sticky" // Mantener fijo en la parte inferior
      sx={{
        bottom: '0vh', // Fijado en la parte inferior de la ventana
        left: 0, // Alineado con el borde izquierdo
        right: 0, // Se extiende hasta el borde derecho
        background: 'linear-gradient(to right, #FFC0CB, #FFFF99)', // Estilo del fondo
        zIndex: 1000, // Asegura que esté encima de otros elementos
      }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          variant="h6"
          sx={{
            textAlign: 'right',
            fontFamily: "'Creepster', cursive",
            fontSize: '4vh',
            color: 'black',
          }}
        >
          Reserva ahora mismo
        </Typography>
        <Box>
          <WhatsappButton />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
