import { Container, Typography } from "@mui/material";
import ReactPlayer from "react-player/lazy";
import { BackButton } from "../common/BackButton";
import { CDNUrl } from "../common/config";

const video2 = `${CDNUrl}/Portfolio/video2LOW.mp4`;

export default function SocialEvents() {
    return (<Container>
        <BackButton url="/"></BackButton>
        <Typography className="highlight" component="div" fontSize='12px'>
            <h3>PORTAFOLIO</h3>
        </Typography>
        <br />
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            Me desempeño hace más de 15 años como
            profesional en el ámbito de la belleza.
            Maiimakeup está creado en función a servir.
            Tengo por objetivo brindar el mejor Servicio
            como Makeup Artist.
        </Typography>
        <br />
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            Conocimientos esenciales, Makeup para cine |TV | Fotografía Profesional |Novias | 15 años | Social | Clases | Técnicas de Maquillaje profesional, Maquillaje profesional para los diferentes ámbitos de la vida.
        </Typography>
        <br />
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            Maiimakeup Brinda un espacio apropiado,
            totalmente personalizado, servicio de
            maquillaje y peinado a domicilio 24 horas
            con más servicios adicionales dedicado al arte y la belleza. Depilación,
            Diseño de cejas, cepillados, ondas, alisados, enjuagues tono sobre tono, Cortes, uñas y más.
        </Typography>
        <br />
        <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
            Enfocándome en los Fundamentos del
            Maquillaje y lo que puede aportar en el
            ámbito social y personal.
        </Typography>
        <br />
        <br />
        <br />
        <br />

    </Container >)
}