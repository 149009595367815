import { ISong } from "./ISong";

const HALLOWEEN: ISong[] =

    [
        {
            name: 'Ghostbusters Parker J.R.',
            id: 0,
            url: 'https://soundcloud.com/newworldbass1/the-living-tombstone-the-ghostbusters-theme-remix?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing',

        },
        {
            name: 'Thriller Michael Jackson',
            id: 1,
            url: 'https://soundcloud.com/darion-lopez-38557354/thriller-michael-jackson?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing',

        },
        {
            name: 'This is Halloween',
            id: 2,
            url: 'https://soundcloud.com/hammerstudentassociation/this-is-halloween-the?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing'
        },
        {
            name: 'The Adams Family',
            id: 3,
            url: 'https://soundcloud.com/thespooksofficial/the-addams-family-tv-theme?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing'
        }
    ];

export default HALLOWEEN;

