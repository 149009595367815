import { Container, ImageList, ImageListItem, ImageListItemBar, Typography } from "@mui/material";
import { BackButton } from "../common/BackButton";
import HAIRSTYLES from './data';

function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}

export default function HairStyle() {
    return (
        <Container>
            <BackButton url='/'></BackButton>
            <Typography className="highlight" component="div" fontSize='12px'>
                <h3>PEINADOS</h3>
            </Typography>
            <br />
            <ImageList
                sx={{ width: "100%", height: 'auto' }}
                variant="quilted"
                cols={2}
            >
                {HAIRSTYLES.map((item) => (
                    <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                        <img
                            {...srcset(item.img, 1, item.rows, item.cols)}
                            alt={item.keywords}
                            loading="lazy"
                        />
                        <ImageListItemBar
                            sx={{ backgroundColor: item.title.length === 0 ? 'transparent' : '' }}
                            title={item.title}                            
                            subtitle={item.description}
                        />
                    </ImageListItem>
                ))}
            </ImageList>
            <br />
            <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
                Puede que una de las razones principales
                por la que el mundo de la belleza es tan
                lucrativo y una de mis pasiones es por cómo
                hago que las personas se sientan bajo la
                misma piel, cumplo con darte un toque
                personal cumpliendo con lo que buscas para
                tu ocasión, acompañándote en tu fecha especial.
            </Typography>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </Container>

    );

}