import { Box, Card, CardActions, CardContent, CardMedia, Grid, Typography } from "@mui/material"
import { isMobile } from "react-device-detect";
import { ILayout } from "./ILayout";
import MENU from "./data";
import { ButtonLink } from "./ButtonLink";
import { CSSProperties } from "styled-components";
import Container from "@mui/material/Container";
import HalloweenMenu from "../halloween/Menu/HalloweenMenu";
import { TopBar } from "../TopBar";


const containerStyle: CSSProperties =
{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    background: 'transparent',
};

function BuildMobileItem(item: ILayout, delay: number) {

    return (
        <ButtonLink key={item.id} delay={delay} url={item.to} title={item.title} icon={item.icon} />
    );
}

function BuildDestopItem(item: ILayout, delay: number) {
    return (

        <Grid key={item.id} item xs={4}   >
            <Card key={item.id} sx={{ margin: '10px', minHeight: '40vh' }}  >
                <CardMedia
                    component="img"
                    height="150vh"
                    image={item.img}
                    alt={item.title}
                />
                <CardContent sx={{ height: '10vh' }}>
                    <Typography sx={{ mb: 1.5 }} fontSize={5} >
                        {item.text}
                    </Typography>
                </CardContent>
                <CardActions sx={{ width: '100%' }}>
                    <ButtonLink delay={delay} url={item.to} title={item.title} icon={item.icon} />
                </CardActions>
            </Card></Grid>);
}

export function Layout() {

    if (isMobile) {
        return (<Box style={containerStyle} sx={{ padding: '0vh' }}>
            <TopBar />
            <HalloweenMenu />
            <Container>
                {MENU.map((item: ILayout, index) => { return BuildMobileItem(item, index * 100) })}
            </Container>
        </Box>);
    }
    else {
        return (
            <Container sx={{ padding: '0vh' }}>
                <TopBar />
                <HalloweenMenu />
                <Grid container style={{
                    padding: '10vh',
                }}>

                    {MENU.map((item: ILayout, index) => { return BuildDestopItem(item, index * 100) })}
                </Grid>
            </Container>
        );
    }
}

export default Layout;