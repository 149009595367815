import { ILayout } from "./ILayout";

import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import BrushIcon from '@mui/icons-material/Brush';
import Face2Icon from '@mui/icons-material/Face2';
import ChurchIcon from '@mui/icons-material/Church';
import Groups2Icon from '@mui/icons-material/Groups2';
import { CSSProperties } from "styled-components";

const iconStyle: CSSProperties =
{
    position: 'absolute',
    left: '0vh',
    marginLeft: '1vh',
    color:'gray'
}

const MENU: ILayout[] =
    [
        {            
            id: 1,
            img: require('./img/maquillaje.jpg'),
            text: 'Maquillaje profesional',
            title: 'MAQUILLAJES',
            to: '/maquillaje',
            icon: <BrushIcon style={iconStyle} />
        },
        {
            id: 2,
            img: require('./img/peinados.jpg'),
            text: 'Peinado original',
            title: 'PEINADOS',
            to: '/peinados',
            icon: <Face2Icon style={iconStyle} />
        },       
        {
            id: 4,
            img: require('./img/clases.jpg'),
            text: 'Aprende y conviértete en una profesional makeup',
            title: 'CLASES',
            to: '/cursos',
            icon: <CastForEducationIcon style={iconStyle} />
        },
        {            
            id: 5,
            img: require('./img/eventos.jpg'),
            text: 'Portafolio de trabajo para eventos',
            title: 'PORTAFOLIO',
            to: '/portafolio',
            icon: <Groups2Icon style={iconStyle} />
        },
        {            
            id: 6,
            img: require('./img/productos.jpg'),
            text: 'Productos para tu belleza',
            title: 'PRODUCTOS',
            to: '/productos',
            icon: <Groups2Icon style={iconStyle} />
        }              
    ]

export default MENU;