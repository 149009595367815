import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FC, useState } from "react";


export const WindowDialog: FC<{ title: string, message: string, handleOpen:any }> = (params) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        window.location.href="/";        
    };

    return (<Dialog
        open={params.handleOpen}
        onClose={handleClose}        
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {params.title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {params.message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button sx={{ minWidth: '50%', margin: '5px' }} onClick={handleClose} autoFocus>
                Aceptar
            </Button>
        </DialogActions>
    </Dialog>);
}
