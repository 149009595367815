import React from "react";
import { Container, ImageList, ImageListItemBar, ImageListItem, Typography } from "@mui/material";
import HALLOWEENMAKEUP from "./data"; // Asegúrate de que este archivo tenga el array con las imágenes
import { BackButton } from "../common/BackButton";
import MusicPlayer from "../music-player/MusicPlayer";

const Halloween: React.FC = () => {

    function srcset(image: string, size: number, rows = 1, cols = 1) {
        return {
            src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
            srcSet: `${image}?w=${size * cols}&h=${size * rows
                }&fit=crop&auto=format&dpr=2 2x`,
        };
    }

    return (
        <Container>
            <BackButton url="/" />
            <Typography className="highlight" component="div" fontSize='12px'>
                <h3>HALLOWEEN 2024</h3>
            </Typography>
            <MusicPlayer />

            <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
                ¡Transforma tu apariencia este Halloween con maquillajes espectaculares! ¿Quieres ser una bruja aterradora, un hombre lobo feroz o un zombi escalofriante? ¿Qué tal Dracula o Frankenstein? ¡No hay límites! Deja volar tu imaginación y crea el personaje perfecto para sorprender a todos. ¡Reserva tu cita ahora y luce un look de miedo!
            </Typography>

            <ImageList
                sx={{
                    width: "100%",
                    height: 'auto',
                    gridTemplateColumns: 'repeat(2, 1fr)', // Forzar 2 imágenes por fila
                    gap: 8, // Espacio entre las imágenes
                }}
                variant="quilted"
            >
                {HALLOWEENMAKEUP.map((item) => (
                    <ImageListItem key={item.img} cols={1} rows={1}>
                        <img
                            {...srcset(item.img, 1, item.rows, item.cols)}
                            alt={item.keywords}
                            loading="lazy"
                        />
                        <ImageListItemBar
                            sx={{ backgroundColor: item.title.length === 0 ? 'transparent' : '' }}
                            title={item.title}
                            subtitle={item.description}
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </Container>
    );
};

export default Halloween;
