
import { Container, ImageListItem, ImageListItemBar, Typography } from "@mui/material";
import { FC } from "react";
import { BackButton } from "../common/BackButton";
import COURSES from './data';

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export const Courses: FC<{}> = () => {

  return (
    <Container >
      <BackButton url='/' />
      <Typography className="highlight" component="div" fontSize='12px'>
        <h3>CLASES</h3>
      </Typography>
      <br />
      <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
        Aquí encontraras todo mi conocimiento, tips de belleza y muchísimo más.
        Un espacio creado para los amantes del maquillaje donde aprenderás y emprenderás!
      </Typography>
      <br />
      <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
        Invitándote a crear nuevos looks, despertar tu creatividad y así expresarte de la mejor manera.
      </Typography>
      <br />
      <Container style={{ textAlign: 'center' }}><strong>CLASES PERSONALIZADAS</strong></Container>
      <br />
      {COURSES.map((item) => (
        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1} style={{ marginBottom: '1vh' }}>
          <img style={{ borderRadius: '1vh' }}
            {...srcset(item.img, 1, item.rows, item.cols)}
            alt={item.keywords}
            loading="lazy"
          />
          <ImageListItemBar
            sx={{ backgroundColor: item.title.length === 0 ? 'transparent' : '' }}
            title={item.title}
            subtitle={item.description}
          />
        </ImageListItem>
      ))}
      <br />
      <Container style={{ textAlign: 'center' }}><strong>CLASES DE AUTOMAQUILLAJE PROFESIONAL</strong></Container>
      <br />
      <ul>

        <li>Aprende a maquillarte super facil!</li>
        <li>Los mejores tips de belleza.</li>
        <li>Cómo sacarle provecho a tu kit de maquillaje!</li>
        <li>Conocer nuevos productos del amplio mundo del makeup.</li>
      </ul>
      <br />
      <Typography className="highlight" component="div" fontSize='12px'>
        <h3>A MAQUILLARTE DESDE CERO</h3>
      </Typography>
      <Container style={{ textAlign: 'center' }}><strong>FUNDAMENTOS DEL MAQUILLAJE</strong></Container>

      <ul>
        <li>Higiene y protocolo</li>
        <li>Productos y herramientas</li>
        <li>Tipo de piel y preparación</li>
        <li>Teoría del color</li>
        <li>Correciones del color</li>
        <li>Aplicación de base y acabados</li>
        <li>Morfología y visagismo</li>
        <li>Iluminaciones y contornos</li>
      </ul>

      <Typography className="highlight" component="div" fontSize='12px'>
        <h3>CLASES / TEORICO - PRACTICO</h3>
      </Typography>


      <br />
      <Typography><strong>CLASES TEÓRICO PRÁCTICO</strong></Typography>
      <Typography ><strong>MODULOS</strong></Typography>
      <br/>
      <Container style={{ display: 'flex', flexDirection: 'column' }}>
        <span>1. Preparación y cuidado de la piel</span>
        <span>Aprenderás skincare</span>
        <br />
        <span>2. Color y Rostro</span>
        <span>Contornos, correciones e iluminación</span>

        <br />
        <span>3. Preparación de piel full cover</span>
        <span>Unifica tu piel</span>
        <br />
        <span>4. Maquillaje para el día a día - Básico</span>
        <br />

        <span>5. Técnicas de ojos </span>
        <span>Diversas técnicas</span>
        <span>Postura - aplicación de pestañas</span>
        <span>Diseño de Cejas</span>
        <br />
        <span>6. Técnicas de Labios</span>
        <br />

        <span>7. Maquillaje completo</span>
        <span>Incluye todos los modulos</span>

        <br/>
      </Container>
      <Typography className="highlight" component="div" fontSize='12px'>
        <h3>INCLUYE</h3>
      </Typography>
      <ul>
        <li>Kit de brochas</li>
        <li>Producto Sorpresa</li>
        <li>Pestañas postizas</li>
        <li>Refrigerio</li>
      </ul>

      <br />
      <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
        Aquí están las diferentes opciones que tienes para adquirir conocimiento.
      </Typography>
      <br />
      <Typography textAlign='justify' style={{ textAlignLast: 'left' }}>
        Recuerda que tienes opciones.
        Puedes programar tu clase para diferentes fechas ya que tienes la facilidad de hacerlo por módulos.
      </Typography>
      <br />
      <Typography className="highlight" component="div" fontSize='12px'>
        <h3>BENEFICIOS DE MAKEUP PROFESIONAL</h3>
      </Typography>

      <ul>
        <li>Garantizo durabilidad de 15 horas.</li>
        <li>Al tomar fotos, el maquillaje se verá logrando la foto de tus sueños</li>
        <li>Es una excelente elección para una fecha especial</li>
        <li>Adquieres conocimientos, pasos y tips.</li>
        <li>Estarás satisfecha</li>
      </ul>
      <br />
      <br />

      <Typography textAlign='justify' component='div' style={{ textAlignLast: 'left', color: 'gray' }}>
        Los cursos incluyen:
        <br />
        <ul>
          <li>Fundamentos</li>
          <li>Induccion</li>
          <li>Técnicas</li>
          <li>Clase para principiantes</li>
          <li>Clase personalizada</li>
          <li>Clase intensiva</li>
          <li>Clase virtual</li>
          <li>Clase grupal</li>
        </ul>
      </Typography>

      <br />
      <br />
      <br />
      <br />
    </Container >
  );
}
