import { CDNUrl } from "../common/config";

const coursesUrl = `${CDNUrl}/courses`;

const COURSES =
    [   
        { rows: 1, cols: 1, title: '', description: '', keywords: 'corte cabello profesional', img: `${coursesUrl}/01.jpg` },
        { rows: 1, cols: 1, title: '', description: '', keywords: 'corte cabello profesional', img: `${coursesUrl}/02.jpg` },
        { rows: 1, cols: 1, title: '', description: '', keywords: 'corte cabello profesional', img: `${coursesUrl}/03.jpg` },        
        { rows: 1, cols: 1, title: '', description: '', keywords: 'corte cabello profesional', img: `${coursesUrl}/04.jpg` },        
        { rows: 1, cols: 1, title: '', description: '', keywords: 'corte cabello profesional', img: `${coursesUrl}/05.jpg` },        
        { rows: 1, cols: 1, title: '', description: '', keywords: 'corte cabello profesional', img: `${coursesUrl}/06.jpg` },
        { rows: 1, cols: 1, title: '', description: '', keywords: 'corte cabello profesional', img: `${coursesUrl}/07.jpg` },        
        
    ];

export default COURSES;