import { useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player/lazy';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import Button from '@mui/material/Button';
import { ISong } from './ISong';
import { Container } from '@mui/material';
import './styles.css';
import HALLOWEEN from './data-halloween';

function selectSong(SongList: Array<ISong>) {
    const index = Math.floor(Math.random() * SongList.length);
    console.log('Audio:' + SongList[index].name);
    return SongList[index];
}

export default function MusicPlayer() {
    const [song, setSong] = useState<ISong | null>(null);
    const [playing, setPlaying] = useState(false);  // Empieza como false para que no reproduzca hasta estar listo
    const [ready, setReady] = useState(false);  // Para manejar cuándo el componente está listo
    const buttonRef = useRef<HTMLButtonElement | null>(null);  // Referencia al botón

    useEffect(() => {
        // Selecciona una canción al cargar el componente
        let s = selectSong(HALLOWEEN);
        setSong(s);
    }, []);

    useEffect(() => {
        // Verifica si el botón está disponible y si el reproductor está listo
        if (song && ready && buttonRef.current) {            
            buttonRef.current.click();  // Simula el clic en el botón
        }
    }, [song, ready]);  // Ejecuta el efecto cuando la canción y el estado ready cambian

    const handleReady = () => {
        setReady(true);  // Marca el reproductor como listo
    };

    let icon = playing ? <StopCircleIcon /> : <PlayCircleIcon />;

    if (song != null) {
        return (
            <Container sx={{ textAlign: 'center' }}>
                <Button
                    ref={buttonRef}  // Referencia al botón
                    style={{ textOverflow: 'ellipsis' }}
                    onClick={() => setPlaying(!playing)}  // Cambia el estado de reproducción
                >
                    {song.name} {icon}
                </Button>
                <div className='player-wrapper'>
                    <ReactPlayer
                        className='react-player'
                        loop={true}
                        pip={false}
                        playing={playing}  // Reproduce si el estado de playing es true
                        url={song.url}
                        volume={0.6}
                        onReady={handleReady}  // Marca el reproductor como listo
                        onPause={() => {
                            console.log("I have been paused");
                        }}
                    />
                </div>
            </Container>
        );
    } else {
        return <></>;
    }
}
