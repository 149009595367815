import { isMobile } from 'react-device-detect';
import { CSSProperties } from 'styled-components';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import FaceRounded from './common/FaceRounded';
import MusicPlayer from './music-player/MusicPlayer';

const textStyle: CSSProperties =
{
    fontFamily: 'Tuesday Night',
    fontSize: 'auto',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    width: '100%',
    paddingTop: '5vh',
    paddingBottom: '3vh',
    display: 'block',
    position: 'relative',
    top: '-8vh'
};

const fontSize = isMobile ? '8vh' : '15vh';
const fontSizeSubTitle = isMobile ? '1.3vh' : '5vh';

function Render() {

    return (
        <Container className='title-box-background'>
            <FaceRounded></FaceRounded>            
            <Typography component='div' align='center' fontSize={fontSize} className='main-title' style={textStyle}>
                Malory Orozco
            </Typography>            
            <Typography component='div' align='center' style={{
                borderColor: 'black',
                borderStyle: 'solid',
                borderWidth: '0.3vh',
                bottom: '2vh',
                color: 'black',
                fontWeight: 'bold',
                letterSpacing: '2vh',
                marginLeft: '5vh',
                marginRight: '5vh',
                padding: '2vh',
                position: 'relative',
            }} fontSize={fontSizeSubTitle}>
                MAIIMAKEUP
            </Typography>
            <MusicPlayer/>
        </Container>
    );
}

export function TopBar() {
    return (Render());
}

