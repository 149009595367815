import { CDNUrl } from "../common/config";

const url = `${CDNUrl}`;

const HALLOWEENMAKEUP =
    [

        { rows: 1, cols: 2, keywords: '', title: 'Halloween', description: 'Look impactante', img: `${url}/models/14.jpg` },
        { rows: 1, cols: 2, keywords: '', title: 'Halloween', description: 'Maquillaje de bruja', img: `${url}/models/13.jpg` },
        { rows: 1, cols: 2, keywords: '', title: 'Halloween', description: 'Estilo único', img: `${url}/models/17.jpg` },

        { rows: 1, cols: 2, keywords: '', title: 'Halloween', description: 'Maquillaje exclusivo', img: `${url}/models/26.jpg` },
        { rows: 1, cols: 2, keywords: '', title: 'Halloween', description: 'Look aterrador', img: `${url}/models/27.jpg` },
        { rows: 1, cols: 2, keywords: '', title: 'Halloween', description: 'Estilo deslumbrante', img: `${url}/models/28.jpg` },
        { rows: 1, cols: 2, keywords: '', title: 'Halloween', description: 'Destaca este Halloween', img: `${url}/models/29.jpg` },
        { rows: 1, cols: 2, keywords: '', title: 'Halloween', description: 'Personaje inolvidable', img: `${url}/models/30.jpg` },
        { rows: 1, cols: 2, keywords: '', title: 'Halloween', description: 'Crea tu estilo', img: `${url}/models/31.jpg` },
        { rows: 1, cols: 2, keywords: '', title: 'Halloween', description: 'Maquillaje perfecto', img: `${url}/models/32.jpg` },

    ];



export default HALLOWEENMAKEUP;