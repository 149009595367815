import { Container, ImageList, ImageListItem, ImageListItemBar, Typography } from "@mui/material";
import { BackButton } from "../common/BackButton";
import { PICTURES } from '../makeup/data';
import ReactPlayer from "react-player/lazy";
import { CDNUrl } from "../common/config";

const videoUrl = `${CDNUrl}/models/25.mp4`;


export default function MakeUp() {

    function srcset(image: string, size: number, rows = 1, cols = 1) {
        return {
            src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
            srcSet: `${image}?w=${size * cols}&h=${size * rows
                }&fit=crop&auto=format&dpr=2 2x`,
        };
    }

    return (
        <Container>
            <BackButton url='/'></BackButton>
            <Typography className="highlight" component="div" fontSize='12px'>
                <h3>TÉCNICAS DE MAQUILLAJE</h3>
            </Typography>
            <br />

            <ImageList
                sx={{ width: "100%", height: 'auto' }}
                variant="quilted"
                cols={2}
            >
                {PICTURES.map((item, index) => (
                    <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                        <img
                            {...srcset(item.img, 1, item.rows, item.cols)}
                            alt={item.keywords}
                            loading="lazy"
                            style={{ border: "none", verticalAlign: index === 0 ? "middle" : "" }} // Agregar estilo verticalAlign
                        />
                        <ImageListItemBar
                            sx={{ backgroundColor: item.title.length === 0 ? "transparent" : "" }}
                            title={item.title}
                            subtitle={item.description}
                        />
                    </ImageListItem>
                ))}

            </ImageList>

            <br />
            <br />
            <ReactPlayer
                muted loop={true} volume={0} playing={true}
                width='100%'
                height='auto'
                url={videoUrl} />                       
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </Container>
    );

}