import { CDNUrl } from "../common/config";
const modelUrl = `${CDNUrl}/models/`;
const url = `${CDNUrl}`;

const HAIRSTYLES =
    [
        { rows: 1, cols: 2, title: 'Estilos originales', description: 'Elige el tuyo', img: `${url}/hairstyles/01.jpg` },
        { rows: 1, cols: 1, title: '', description: '', keywords: 'corte cabello profesional', img: `${url}/hairstyles/02.jpg` },
        { rows: 1, cols: 1, title: '', description: '', keywords: 'corte cabello profesional', img: `${url}/hairstyles/07.jpg` },
        { rows: 1, cols: 1, title: '', description: '', keywords: 'corte cabello profesional', img: `${url}/hairstyles/06.jpg` },        
        { rows: 1, cols: 1, title: '', description: '', keywords: 'corte cabello profesional', img: `${url}/hairstyles/10.jpg` },        
        { rows: 1, cols: 1, title: '', description: '', keywords: 'corte cabello profesional', img: `${url}/hairstyles/11.jpg` },        
        { rows: 1, cols: 1, title: '', description: '', keywords: 'corte cabello profesional', img: `${url}/hairstyles/13.jpg` },
        { rows: 1, cols: 1, title: '', description: '', keywords: 'corte cabello profesional', img: `${url}/hairstyles/14.jpg` },
        { rows: 1, cols: 1, title: '', description: '', keywords: 'corte cabello profesional', img: modelUrl + '07.jpg' },        
        { rows: 1, cols: 1, title: '', keywords: 'maquillaje profesional base maquillaje', description: null, img: modelUrl + '15.jpg' },
    ];

export default HAIRSTYLES;