import inventario from './inventario.json';
import categories from './category.json';

export const getProduct = (id: number) => {

  return inventario.find((element) => element.id === id);
}

export const getProducts = () => {

  return inventario.sort(function (a, b) {
    var nameA = a.item_group_id.toUpperCase(); // ignore upper and lowercase
    var nameB = b.item_group_id.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

}

export const getFirstProduct = () => {
  return inventario.shift();
}

export const getCategories = () => {
  let result: string[] = [];
  categories.sort((a, b) => a.order > b.order ? 1 : -1,).map(p => result.push(p.name));
  return result;
}

export const getByCategory = (category: string) => {
  return inventario.filter(x => x.item_group_id === category)
}