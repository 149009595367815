import { CDNUrl } from "../common/config";

const urlPortfolio = `${CDNUrl}/Portfolio/`;
const urlModels = `${CDNUrl}/models/`;

export const PICTURES = [    
    { rows: 1, cols: 1, title: '', keywords: 'maquillaje profesional delineacion de ojos', description: null, img: urlPortfolio + '3.jpeg' },        

    { rows: 1, cols: 1, title: '', keywords: 'maquillaje profesional cejas radiantes', description: null, img: urlPortfolio + '7.jpeg' },
    { rows: 1, cols: 1, title: '', keywords: 'maquillaje profesional cejas radiantes', description: null, img: urlPortfolio + '9.jpeg' },
    { rows: 1, cols: 1, title: '', keywords: 'maquillaje profesional cejas radiantes', description: null, img: urlModels + '01.jpg' },
    { rows: 1, cols: 1, title: '', keywords: 'maquillaje profesional base maquillaje', description: null, img: urlPortfolio + '6.jpeg' },
    { rows: 1, cols: 1, title: '', keywords: 'maquillaje profesional base maquillaje', description: null, img: urlModels + '12.jpg' },
    { rows: 1, cols: 1, title: '', keywords: 'maquillaje profesional base maquillaje', description: null, img: urlModels + '16.jpg' },
    { rows: 1, cols: 1, title: '', keywords: 'maquillaje profesional base maquillaje', description: null, img: urlModels + '18.jpg' },
    { rows: 1, cols: 1, title: '', keywords: 'maquillaje profesional base maquillaje', description: null, img: urlModels + '19.jpg' },
    { rows: 1, cols: 1, title: '', keywords: 'maquillaje profesional base maquillaje', description: null, img: urlModels + '20.jpg' },
    { rows: 1, cols: 1, title: '', keywords: 'maquillaje profesional base maquillaje', description: null, img: urlModels + '22.jpg' },
    { rows: 1, cols: 1, title: '', keywords: 'maquillaje profesional base maquillaje', description: null, img: urlModels + '23.jpg' },
    { rows: 1, cols: 1, title: '', description: '', keywords: 'corte cabello profesional', img: urlModels + '09.jpg' },
    { rows: 1, cols: 1, title: '', description: '', keywords: 'corte cabello profesional', img: urlModels + '02.jpg' },
    { rows: 1, cols: 1, title: '', description: '', keywords: 'corte cabello profesional', img: urlModels + '03.jpg' },
    { rows: 1, cols: 1, title: '', keywords: 'maquillaje profesional base maquillaje', description: null, img: urlModels + '13.jpg' },    
    { rows: 1, cols: 1, title: '', keywords: 'maquillaje profesional base maquillaje', description: null, img: urlModels + '21.jpg' },
    { rows: 1, cols: 1, title: '', keywords: 'maquillaje profesional base maquillaje', description: null, img: urlModels + '17.jpg' },

];