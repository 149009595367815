
const HALLOWEENIMAGES =
[
    {
        img:require('./img/Dracula.jpg')
    },
    {
        img:require('./img/Bruja.jpg')
    },
    {
        img:require('./img/Zoombie.jpg')
    }

];

export default HALLOWEENIMAGES;