import { Container, Table, TableCell, TableRow, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { getByCategory, getCategories } from '../services/ProductsDao';
import { CSSProperties, FC } from "react";
import { Box } from "@mui/system";
import { BackButton } from "../common/BackButton";


var nequi = require('./nequi.png');
var efecty = require('./efecty.png');

const cellStyleLeft: CSSProperties =
{
  border: 'none',
  width: '20%',
  padding: '3px'
}
const cellStyleRigth: CSSProperties =
{
  border: 'none',
  width: '80%',
  padding: '3px'
}

const paymentStyles: CSSProperties =
{
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  alignItems: 'center'
}

const imageStyle: CSSProperties = {
  width: '40%',
  height: 'auto',
  background: 'white',
  margin: '1vh',
  borderRadius: '2vh'
}


const RenderTable = (category: string) => {
  let marcaAnterior = '';
  return (
    <Table component='div' >
      {getByCategory(category).map((x) => {

        const isDifferent = x.brand !== marcaAnterior;
        marcaAnterior = x.brand;

        return (
          <TableRow component='div' key={x.id}>
            <TableCell component='div' style={cellStyleLeft}>
              {isDifferent ? x.brand : ''}
            </TableCell>
            <TableCell component='div' style={cellStyleRigth} sx={{ textAlign: 'left' }}>
              <Link style={{ textAlign: 'left', textOverflow: 'ellipsis' }} to={`/producto/${x.id}`} key={x.id}>{x.title}</Link>
            </TableCell>
          </TableRow>);
      })}
    </Table>);
}

const BuildCard = (category: string) => {
  return (
    <Box key={category} sx={{ paddingBottom: '10px' }}>
      <Card>
        <CardHeader title={
          <Typography className="highlight" component="div" fontSize='12px'>
            <h3>{category.toUpperCase()}</h3>
          </Typography>
        }
        />
        <CardContent component='div'>
          {RenderTable(category)}
        </CardContent>
      </Card>
    </Box >);
}

export const ListProducts: FC<{}> = () => {

  let categories: string[] = getCategories()!;

  return (
    <Container sx={{ margin: 0, padding: 0 }}>
      <BackButton url='/'></BackButton>
      <Container fixed>
        {categories.map(c => BuildCard(c))}
      </Container>
      <Box sx={{ textAlign: 'center' }}>
        <h3>Métodos de Pago</h3>
      </Box>
      <Container style={paymentStyles} >
        <img style={imageStyle} src={nequi} alt='nequi' />
        <img style={imageStyle} src={efecty} alt='efecty' />
      </Container>
      <br />
      <br />
      <br />
      <br />
    </Container>
  );
}