import React from "react";
import { motion } from 'framer-motion';
import { Container, styled } from '@mui/system';
import HALLOWEENIMAGES from "./data";
import { Link } from 'react-router-dom';
import { Typography } from "@mui/material";

// Estilizar la imagen
const StyledImage = styled(motion.img)({
  width: '100%',
  maxWidth: '300px',
  borderRadius: '10px',
  objectFit: 'contain',
  position: 'relative',
});

// Estilizar el texto "Halloween" con la fuente Creepster
const TextOverlay = styled(Link)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: '#ff6347', // Un tono más fuerte (tomate)
  fontSize: '3rem', // Texto grande y llamativo
  fontWeight: 'bold',
  textShadow: '4px 4px 8px black', // Sombra de texto para resaltar
  zIndex: 1, // Asegura que esté sobre la imagen
  textDecoration: 'none', // Sin subrayado para el Link
  fontFamily: "'Creepster', cursive", // Aplicar la fuente Creepster
  animation: 'glow 1.5s ease-in-out infinite', // Añade una animación de brillo
  '&:hover': {
    color: '#ff4500', // Color más intenso cuando pasas el mouse
  },
  '@keyframes glow': {
    '0%': {
      textShadow: '0 0 10px #ff6347, 0 0 20px #ff6347, 0 0 30px #ff6347',
    },
    '50%': {
      textShadow: '0 0 20px #ff4500, 0 0 30px #ff4500, 0 0 40px #ff4500',
    },
    '100%': {
      textShadow: '0 0 10px #ff6347, 0 0 20px #ff6347, 0 0 30px #ff6347',
    },
  },
});

// Aplicar animación de temblor solo al span
const AnimatedSpan = styled(motion.span)({
  display: 'inline-block', // Para asegurarnos de que el span sea un bloque en línea
  color: '#ff4500', // Color más intenso para la fecha
});

const HalloweenMenu: React.FC = () => {
  // Selecciona una imagen aleatoria
  const randomIndex = Math.floor(Math.random() * HALLOWEENIMAGES.length);
  const randomImage = HALLOWEENIMAGES[randomIndex].img;

  return (
    <>
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px', position: 'relative' }}>

        <TextOverlay to="/halloween">
          Halloween  <br />
          <AnimatedSpan
            sx={{ color: 'black' }}
            animate={{
              x: [0, -2, 2, -2, 2, 0], // Temblor de miedo en el eje X
            }}
            transition={{
              duration: 0.1, // Rápido para un efecto de temblor
              repeat: Infinity, // Se repite indefinidamente
              ease: "easeInOut", // Suaviza el movimiento
            }}
          >
            OCT 31 2024
          </AnimatedSpan>
          <span>Ver aqui</span>
        </TextOverlay>

        <StyledImage
          src={randomImage}
          alt="Halloween"
          animate={{
            scale: [1, 1.05, 1], // Pequeña escala para que parezca que "late"
            rotate: [0, 1, -1, 0], // Muy poca rotación para dar efecto de temblor
            filter: ['none', 'invert(1)', 'none'], // Aplica y quita el efecto negativo
          }}
          transition={{
            duration: 0.7, // Muy rápido, cada ciclo dura 0.7 segundos
            ease: "easeInOut", // Suaviza la transición
            repeat: Infinity, // Se repite indefinidamente
          }}
          initial={{ scale: 1, rotate: 0, filter: 'none' }} // Estado inicial
        />
      </Container >
      <Typography textAlign='justify' sx={{ paddingLeft: '3vh', paddingRight: '3vh' }}>
        ¡Transforma tu look este Halloween y conviértete en el personaje más aterrador! ¡Reserva ahora!
      </Typography>

    </>
  );
};

export default HalloweenMenu;
