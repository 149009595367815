import logo from './WhatsApp.png';
import { CSSProperties } from "styled-components";
import { GOOGLE_ADS, SendConversion } from '../googleAds/GoogleAdsMethods';

export default function WhatsappButton() {
  const phoneNumber = '+57 304 2057751';
  const message = 'Hola, Quiero información sobre..';
  const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;

  const handleClick = () => {

    // Notifica la conversion en google ads    
    SendConversion(GOOGLE_ADS.WHATSAPPBUTTON);
    window.open(url, '_blank');
  };


  const elementStyle: CSSProperties =
  {
    alignItems: 'center',
    backgroundColor: '#25d366',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    height: '7vh',
    justifyContent: 'space-around',
    margin: '1vh',
    right: '1vh',
    width: '7vh',
    zIndex: '999',    
  };

  return (
    <div onClick={handleClick} style={elementStyle}>
      <img src={logo} alt="WhatsApp" style={{ width: '4vh', height: '4vh' }} />
    </div>
  );
}
